import React, { useEffect, useState } from 'react';
import './OverviewPage.scss';
import clsx from 'clsx';
import { useSpaceClient } from '../../apiClients/spaceClient';
import { useParams } from 'react-router-dom';

export interface IOverviewData {
    einnahmen: number,
    ausgaben: number,
    ergebnis: number
}

export const OverviewPage = () => {
    const [overviewData, setOverviewData] = useState<IOverviewData>({
        einnahmen: 0,
        ausgaben: 0,
        ergebnis: 0
    });

    const params = useParams();
    const spaceClient = useSpaceClient();

    const loadOverviewData = () => {
        if (params.fileName == null)
            return;
        spaceClient.getOverviewFromFile(params.fileName).then((overviewData) => {
            setOverviewData(overviewData);
        }).catch(() => {
            setOverviewData({
                einnahmen: 0,
                ausgaben: 0,
                ergebnis: 0
            });
        });
    };

    const getFormattedNumber = (number: number) => {
        return number.toLocaleString('de') + " EUR";
    }

    useEffect(loadOverviewData, []);

    return (
        <div className='OverviewPage'>
            <h2>{params.fileName != null ? params.fileName : "Datei"}</h2>
            <div className='OverviewPage__Item'>
                <div className='OverviewPage__ItemTitle'>Einnahmen</div>
                <div className='OverviewPage__ItemValue OverviewPage__Einnahmen'>{getFormattedNumber(overviewData.einnahmen)}</div>
            </div>
            <div className='OverviewPage__Item'>
                <div className='OverviewPage__ItemTitle'>Ausgaben</div>
                <div className='OverviewPage__ItemValue OverviewPage__Ausgaben'>{getFormattedNumber(overviewData.ausgaben)}</div>
            </div>
            <div className='OverviewPage__Item'>
                <div className='OverviewPage__ItemTitle'>
                    {
                        overviewData.ergebnis >= 0 ?
                        "Gewinn" :
                        "Verlust"
                    }
                </div>
                <div
                    className={clsx(
                        'OverviewPage__ItemValue',
                        { 'OverviewPage__Gewinn': overviewData.ergebnis >= 0 },
                        { 'OverviewPage__Verlust': overviewData.ergebnis < 0 }
                    )}
                >
                    {getFormattedNumber(overviewData.ergebnis)}
                </div>
            </div>
        </div>
    );
}