import React, { useRef, useState } from 'react';
import './UploadFilePage.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { useSpaceClient } from '../../apiClients/spaceClient';
import { ErrorOutlined } from '@mui/icons-material';

export const UploadFilePage = () => {
    const navigate = useNavigate();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const spaceClient = useSpaceClient();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const onSelectButtonClick = () => {
        if (fileInputRef.current == null)
            return;
        fileInputRef.current.click();
    };

    const onFileSelect = () => {
        if (fileInputRef.current == null || fileInputRef.current.files == null)
            return;
        setIsLoading(true);
        const file = fileInputRef.current.files[0];
        const fileReader = new FileReader();
        fileReader.readAsText(file, "UTF-8");
        fileReader.onload = (event) => {
            if (event.target == null)
                return;
            const fileContent = event.target.result as string;
            const fileName = file.name;

            spaceClient.uploadFile({
                fileName,
                fileContent
            }).then(() => navigate("/file/overview"))
            .catch(errorMessage => {
                setErrorMessage(errorMessage);
                setIsLoading(false);
            });
        };
    };

    return (
        <div className='UploadFilePage'>
            <h2>Datei hochladen</h2>
            <div className='UploadFilePage__SelectSection'>
                <span className='UploadFilePage__Hint'>Bitte wähle eine CSV-Datei aus:</span>
                {
                    errorMessage.length > 0 ? (
                        <div className='UploadFilePage__ErrorMessage'>
                            <ErrorOutlined className='UploadFilePage__ErrorIcon'/>
                            {errorMessage}
                        </div>
                    ) : null
                }
                <Button
                    variant='contained'
                    onClick={onSelectButtonClick}
                    className='UploadFilePage__UploadButton'
                    disabled={isLoading}
                >
                    {
                        isLoading ?
                            "Bitte warten..." :
                            "Datei hochladen"
                    }
                </Button>
            </div>
            <input
                className='UploadFilePage__FileInput'
                type="file"
                ref={fileInputRef}
                accept="text/csv"
                onChange={onFileSelect}
            />
        </div>
    );
}