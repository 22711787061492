import { Button, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import React from 'react';
import { OverviewPage } from './pages/OverviewPage/OverviewPage';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import { CreateSpacePage } from './pages/CreateSpacePage/CreateSpacePage';
import { SpaceLoginPage } from './pages/SpaceLoginPage/SpaceLoginPage';
import { FileOverviewPage } from './pages/FileOverviewPage/FileOverviewPage';
import { UploadFilePage } from './pages/UploadFilePage/UploadFilePage';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <SpaceLoginPage />,
  },
  {
    path: "/overview/:fileName",
    element: <OverviewPage />,
  },
  {
    path: "/file/upload",
    element: <UploadFilePage />,
  },
  {
    path: "/space/create",
    element: <CreateSpacePage />,
  },
  {
    path: "/space/login",
    element: <SpaceLoginPage />,
  },
  {
    path: "/file/overview",
    element: <FileOverviewPage />,
  }
]);

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className='App'>
        <RouterProvider router={router} />
      </div>
    </ThemeProvider>
  );
}

export default App;
