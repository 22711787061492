import React, { useRef, useState } from 'react';
import './CreateSpacePage.scss'
import { Button, TextField } from '@mui/material';
import { ErrorOutlined } from '@mui/icons-material';
import { useSpaceClient } from '../../apiClients/spaceClient';
import { Link, useNavigate } from 'react-router-dom';

export const CreateSpacePage = () => {
    const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const nameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const confirmPasswordRef = useRef<HTMLInputElement>(null);
    const spaceClient = useSpaceClient();
    const navigate = useNavigate();

    const updateCreateButtonDisabled = () => {
        if (nameRef.current == null || passwordRef.current == null || confirmPasswordRef.current == null) {
            setCreateButtonDisabled(true);
            return;
        }

        const isNameValid = nameRef.current.value.length > 0;
        const isPasswordValid = passwordRef.current.value.length > 7;
        const isPasswordConfirmed = passwordRef.current.value === confirmPasswordRef.current.value;

        if (!isNameValid || !isPasswordValid || !isPasswordConfirmed) {
            setCreateButtonDisabled(true);
            return;
        }

        setCreateButtonDisabled(false);
    };

    const createSpace = () => {
        if (nameRef.current == null || passwordRef.current == null)
            return;
        setIsLoading(true);
        spaceClient.createSpace({
            name: nameRef.current.value,
            password: passwordRef.current.value
        }).then(() => navigate('/file/overview'))
        .catch(message => {
            setErrorMessage(message);
            setIsLoading(false);
        });
    };

    return (
        <div className='CreateSpacePage'>
            <h2>Neuen Space erstellen</h2>
            <div className='CreateSpacePage__InputSection'>
                <TextField
                    placeholder='Spacename'
                    size="small"
                    fullWidth
                    inputRef={nameRef}
                    onChange={updateCreateButtonDisabled}
                />
                <TextField
                    className='CreateSpacePage__PasswordField'
                    placeholder='Passwort'
                    size="small"
                    type='password'
                    fullWidth
                    inputRef={passwordRef}
                    onChange={updateCreateButtonDisabled}
                />
                <TextField
                    className='CreateSpacePage__ConfirmPasswordField'
                    placeholder='Passwort wiederholen'
                    size="small"
                    type='password'
                    fullWidth
                    inputRef={confirmPasswordRef}
                    onChange={updateCreateButtonDisabled}
                />
                <div className='CreateSpacePage__PasswordHint'>Das Passwort muss mindestens 8 Zeichen lang sein.</div>
                <div className='CreateSpacePage__SpaceLoginPageLinkContainer'>
                    <Link
                        to="/space/login"
                        className='CreateSpacePage__SpaceLoginPageLink'
                    >
                        Im Space anmelden
                    </Link>
                </div>
                {
                    errorMessage.length > 0 ? (
                        <div className='CreateSpacePage__ErrorMessageContainer'>
                            <div className='CreateSpacePage__ErrorMessage'>
                                <ErrorOutlined className='CreateSpacePage__ErrorIcon'/>
                                {errorMessage}
                            </div>
                        </div>
                    ) : null
                }
                <div className='CreateSpacePage__CreateButtonContainer'>
                    <Button
                        variant="contained"
                        className='CreateSpacePage__CreateButton'
                        disabled={createButtonDisabled || isLoading}
                        onClick={createSpace}
                    >
                        {
                            isLoading ?
                                "Bitte warten..." :
                                "Erstellen"
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
};