import React, { useRef, useState } from 'react';
import './SpaceLoginPage.scss';
import { Button, TextField } from '@mui/material';
import { ErrorOutlined } from '@mui/icons-material';
import { useSpaceClient } from '../../apiClients/spaceClient';
import { Link, useNavigate } from 'react-router-dom';

export const SpaceLoginPage = () => {
    const [loginButtonDisabled, setLoginButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const spaceIdRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const spaceClient = useSpaceClient();
    const navigate = useNavigate();

    const updateLoginButtonDisabled = () => {
        if (spaceIdRef.current == null || passwordRef.current == null) {
            setLoginButtonDisabled(true);
            return;
        }

        const isSpaceIdEmpty = spaceIdRef.current.value.length < 1;
        const isPasswordEmpty = passwordRef.current.value.length < 1;

        if (isSpaceIdEmpty || isPasswordEmpty) {
            setLoginButtonDisabled(true);
            return;
        }

        setLoginButtonDisabled(false);
    };

    const onLoginClick = () => {
        if (spaceIdRef.current == null || passwordRef.current == null)
            return;
        setIsLoading(true);
        spaceClient.loginToSpace({
            spaceId: spaceIdRef.current.value,
            password: passwordRef.current.value
        }).then(() => navigate('/file/overview'))
        .catch(errorMessage => {
            setErrorMessage(errorMessage);
            setIsLoading(false);
        });
    };

    return (
        <div className='SpaceLoginPage'>
            <h2>Einloggen</h2>
            <div className='SpaceLoginPage__InputSection'>
                <TextField
                    className='SpaceLoginPage__SpaceIdField'
                    placeholder='SpaceID'
                    size="small"
                    fullWidth
                    onChange={updateLoginButtonDisabled}
                    inputRef={spaceIdRef}
                />
                <TextField
                    className='SpaceLoginPage__PasswordField'
                    placeholder='Passwort'
                    size="small"
                    fullWidth
                    type='password'
                    onChange={updateLoginButtonDisabled}
                    inputRef={passwordRef}
                />
                <div className='SpaceLoginPage__CreateSpacePageLinkContainer'>
                    <Link
                        to="/space/create"
                        className='SpaceLoginPage__CreateSpacePageLink'
                    >
                        Neuen Space erstellen
                    </Link>
                </div>
                {
                    errorMessage.length > 0 ? (
                        <div className='SpaceLoginPage__ErrorMessageContainer'>
                            <div className='SpaceLoginPage__ErrorMessage'>
                                <ErrorOutlined className='SpaceLoginPage__ErrorIcon'/>
                                {errorMessage}
                            </div>
                        </div>
                    ) : null
                }
                <div className='SpaceLoginPage__LoginButtonContainer'>
                    <Button
                        className='SpaceLoginPage__LoginButton'
                        variant='contained'
                        disabled={loginButtonDisabled || isLoading}
                        onClick={onLoginClick}
                    >
                        {
                            isLoading ?
                                "Bitte warten..." :
                                "Einloggen"
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
};