import React, { useEffect, useState } from 'react';
import './FileOverviewPage.scss';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { AddOutlined, DeleteOutlined, FileOpenOutlined, FolderOutlined, InfoOutlined, InsertDriveFileOutlined, LogoutOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSpaceClient } from '../../apiClients/spaceClient';

export const FileOverviewPage = () => {
    const [files, setFiles] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [fileToDelete, setFileToDelete] = useState("");
    const [isFileDeletionProcessing, setIsFileDeletionProcessing] = useState(false);
    const navigate = useNavigate();
    const spaceClient = useSpaceClient();

    const onAddButtonClicked = () => {
        navigate("/file/upload");
    };

    const loadFiles = () => {
        setIsLoading(true);
        spaceClient.getFilesFromSpace()
            .then(files => {
                setFiles(files);
                setIsLoading(false);
            })
            .catch(() => {
                setFiles([]);
                setIsLoading(false);
            });
    };

    const onFileItemClick = (fileName: string) => {
        navigate(`/overview/${fileName}`);
    };

    const onDeleteFileClick = (fileName: string, ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        ev.stopPropagation();
        openDeleteDialog(fileName);
    };

    const openDeleteDialog = (fileName: string) => {
        setFileToDelete(fileName);
    };

    const closeDeleteDialog = () => {
        setFileToDelete("");
    };

    const onDeletionConfirmClick = () => {
        setIsFileDeletionProcessing(true);
        spaceClient.deleteFileFromSpace(fileToDelete).then(() => {
            setIsFileDeletionProcessing(false);
            closeDeleteDialog();
            loadFiles();
        }).catch(() => {
            setIsFileDeletionProcessing(false);
            closeDeleteDialog();
        });
    };

    const onLogoutClick = () => {
        spaceClient.logoutFromSpace().then(() => {
            navigate("/space/login");
        }).catch(() => {});
    };

    useEffect(loadFiles, []);

    return (
        <div className='FileOverviewPage'>
            <h2>Meine Dateien</h2>
            { isLoading && <LinearProgress color='inherit' /> }
            {
                files.length > 0 ? (
                    <div className='FileOverviewPage__ListContainer'>
                        <List dense>
                            {
                                files.map(file => (
                                    <ListItemButton
                                        className='FileOverviewPage__ListItemButton'
                                        onClick={() => onFileItemClick(file)}
                                    >
                                        <ListItem
                                            secondaryAction={
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={(ev) => onDeleteFileClick(file, ev)}
                                                >
                                                    <DeleteOutlined />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <InsertDriveFileOutlined />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={file} />
                                        </ListItem>
                                    </ListItemButton>
                                ))
                            }
                        </List>
                    </div>
                ) : null
            }
            {
                files.length < 1 ? (
                    <div className='FileOverviewPage__EmptyList'>
                        <InfoOutlined className='FileOverviewPage__EmptyListIcon' />
                        <span className='FileOverviewPage__EmptyListText'>Keine Dateien</span>
                    </div>
                ) : null
            }
            <Dialog
                open={fileToDelete.length > 0}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={() => {
                    if (!isFileDeletionProcessing) {
                        closeDeleteDialog();
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    Datei löschen
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Möchtest Du wirklich die Datei "{fileToDelete}" löschen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        !isFileDeletionProcessing && (
                            <Button onClick={closeDeleteDialog}>Nein</Button>
                        )
                    }
                    {
                        !isFileDeletionProcessing ? (
                            <Button
                                onClick={onDeletionConfirmClick}
                                color='error'
                                variant='contained'
                            >
                                Ja
                            </Button>
                        ) : (
                            <Button disabled>Bitte warten...</Button>
                        )
                    }
                </DialogActions>
            </Dialog>
            <div className='FileOverviewPage__Footer'>
                <IconButton
                    size='large'
                    onClick={onAddButtonClicked}
                >
                    <AddOutlined className='FileOverviewPage__AddIcon'/>
                </IconButton>
                <IconButton
                    size='large'
                    onClick={onLogoutClick}
                >
                    <LogoutOutlined className='FileOverviewPage__LogoutIcon'/>
                </IconButton>
            </div>
        </div>
    );
};