import axios, { AxiosResponse } from "axios";
import { ICreateSpaceRequest, ILoginToSpaceRequest, IUploadFileRequest } from "./requests";
import { IOverviewData } from "../pages/OverviewPage/OverviewPage";

export interface IUseSpaceClientHook {
    createSpace: (req: ICreateSpaceRequest) => Promise<void>,
    loginToSpace: (req: ILoginToSpaceRequest) => Promise<void>,
    uploadFile: (req: IUploadFileRequest) => Promise<void>,
    getFilesFromSpace: () => Promise<string[]>,
    getOverviewFromFile: (fileName: string) => Promise<IOverviewData>,
    deleteFileFromSpace: (fileName: string) => Promise<void>,
    logoutFromSpace: () => Promise<void>
}

export const useSpaceClient = (): IUseSpaceClientHook => {
    const createSpace = (request: ICreateSpaceRequest) => {
        return new Promise<void>((resolve, reject) => {
            axios({
                url: `${process.env.REACT_APP_BACKEND_BASE_URL}/space/create`,
                method: "POST",
                withCredentials: true,
                data: request
            }).then(() => resolve())
            .catch(error => {
                if (error.response == null) {
                    reject("Es ist ein Fehler aufgetreten.");
                    return;
                }

                reject(error.response.data);
            });
        });
    };

    const loginToSpace = (request: ILoginToSpaceRequest) => {
        return new Promise<void>((resolve, reject) => {
            axios({
                url: `${process.env.REACT_APP_BACKEND_BASE_URL}/space/login`,
                method: "GET",
                withCredentials: true,
                headers: {
                    Authorization: btoa(`${request.spaceId}:${request.password}`)
                }
            }).then(() => resolve())
            .catch(error => {
                if (error.response == null) {
                    reject("Es ist ein Fehler aufgetreten.");
                    return;
                }

                reject(error.response.data);
            });
        });
    };

    const uploadFile = (request: IUploadFileRequest) => {
        return new Promise<void>((resolve, reject) => {
            axios({
                url: `${process.env.REACT_APP_BACKEND_BASE_URL}/space/file/upload?fileName=${request.fileName}`,
                method: "POST",
                withCredentials: true,
                data: request.fileContent
            }).then(() => resolve())
            .catch(error => {
                if (error.response == null) {
                    reject("Es ist ein Fehler aufgetreten.");
                    return;
                }

                reject(error.response.data);
            });
        });
    };

    const getFilesFromSpace = () => {
        return new Promise<string[]>((resolve, reject) => {
            axios({
                url: `${process.env.REACT_APP_BACKEND_BASE_URL}/space/files`,
                method: "GET",
                withCredentials: true
            }).then((response) => resolve(response.data))
            .catch(error => {
                if (error.response == null) {
                    reject("Es ist ein Fehler aufgetreten.");
                    return;
                }

                reject(error.response.data);
            });
        });
    };

    const getOverviewFromFile = (fileName: string) => {
        return new Promise<IOverviewData>((resolve, reject) => {
            axios({
                url: `${process.env.REACT_APP_BACKEND_BASE_URL}/space/file/overview?fileName=${fileName}`,
                method: "GET",
                withCredentials: true
            }).then((response) => resolve(response.data))
            .catch(error => {
                if (error.response == null) {
                    reject("Es ist ein Fehler aufgetreten.");
                    return;
                }

                reject(error.response.data);
            });
        });
    };

    const deleteFileFromSpace = (fileName: string) => {
        return new Promise<void>((resolve, reject) => {
            axios({
                url: `${process.env.REACT_APP_BACKEND_BASE_URL}/space/file/delete?fileName=${fileName}`,
                method: "DELETE",
                withCredentials: true
            }).then(() => resolve())
            .catch(error => {
                if (error.response == null) {
                    reject("Es ist ein Fehler aufgetreten.");
                    return;
                }

                reject(error.response.data);
            });
        });
    };

    const logoutFromSpace = () => {
        return new Promise<void>((resolve, reject) => {
            axios({
                url: `${process.env.REACT_APP_BACKEND_BASE_URL}/space/logout`,
                method: "DELETE",
                withCredentials: true
            }).then(() => resolve())
            .catch(error => {
                if (error.response == null) {
                    reject("Es ist ein Fehler aufgetreten.");
                    return;
                }

                reject(error.response.data);
            });
        });
    };

    return {
        createSpace,
        loginToSpace,
        uploadFile,
        getFilesFromSpace,
        getOverviewFromFile,
        deleteFileFromSpace,
        logoutFromSpace
    };
};